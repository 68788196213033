<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col>
                    <b-form-group class="col-12" :label="$t('name')">
                        <ValidationProvider
                            name="name"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-input-group class="mb-3" prepend="TR">
                                <b-form-input
                                    v-model="formData.name"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            ></b-form-invalid-feedback>
                        </ValidationProvider>
                        <ValidationProvider
                            name="name_en"
                            v-slot="{ valid, errors }"
                        >
                            <b-input-group prepend="EN">
                                <b-form-input
                                    v-model="formData.name_en"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                    <b-form-group :label="$t('rank')">
                        <ValidationProvider name="rank" rules="" v-slot="{ valid, errors }">

                            <b-form-input type="number" v-model="formData.rank">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>

                        </ValidationProvider>
                    </b-form-group>
                    <b-form-group :label="$t('type')">
                        <multi-selectbox :multiple="false" v-model="formData.type"
                                         :options="[{ text: $t('calculation'), value: 'calculation' }, { text: $t('assessment'), value: 'assessment' }]" />
                    </b-form-group>
                    <ValidationProvider
                        name="status"
                        rules=""
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                v-model="formData.status"
                                :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>

                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>

import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

// Services
import ProgramOutcome from "@/services/ProgramOutcome";


// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import CourseActivities from "@/services/CourseActivities";
export default {
    components: {
        StatusSelectbox,
        MultiSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formData: {
             name:null,
             name_en:null,
             status:null,
             type:null
            },
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            CourseActivities.show(id)
                .then(response => {
                    let data = response.data.data;
                        this.formData = {
                            name:data.name,
                            name_en:data.name_en,
                            status:data.status,
                            type:data.type,
                            rank:data.rank
                        }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    CourseActivities.update(this.formId, this.formData)
                        .then(response => {
                            this.$emit('updateFormSuccess', true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.formLoading = false
                        })
                }
        }
    }
}
</script>
